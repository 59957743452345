import React, { useState, useLayoutEffect } from 'react';

import './index.less';

import ico_kefu from '../../resource/home/ico_kefu.png';
import ico_kefu_webp from '../../resource/home/ico_kefu.webp';
import ico_xunwen from '../../resource/home/ico_xunwen.png';
import ico_xunwen_webp from '../../resource/home/ico_xunwen.webp';
import ico_dianhua from '../../resource/home/ico_dianhua(1).png';
import ico_dianhua_webp from '../../resource/home/ico_dianhua(1).webp';
import { showhelptab } from '../../library/util';
import { navigate } from '../../library/reach_router';
import { WebpAlphaImage } from '../../common/webpImage';

var timer: NodeJS.Timer | null = null;

export default function() {
  const [visible, setVisible] = useState(false);
  const [des, setDes] = useState(false);
  useLayoutEffect(() => {
    window.onscroll = function() {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(function() {
        let srcollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (srcollTop > 300) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }, 200);
    };
  }, []);
  return (
    <div id="Anchor">
      <div
        className="main"
        onClick={() => {
          setDes(!des);
        }}
        unselectable="on"
      >
        ·资讯 - 建议
      </div>
      <ul className={'main_des' + (des ? '' : ' active')}>
        <li
          onClick={() => {
            showhelptab();
          }}
        >
          <WebpAlphaImage src={ico_kefu} webp={ico_kefu_webp} />
          在线客服
        </li>
        <li
          onClick={() => {
            navigate('/TrialPage');
          }}
        >
          <WebpAlphaImage src={ico_xunwen} webp={ico_xunwen_webp} />
          产品询价
        </li>
        <li>
          <WebpAlphaImage src={ico_dianhua} webp={ico_dianhua_webp} />
          <a href="tel:155-0213-3698">155-0213-3698</a>
        </li>
      </ul>
      <div
        className="glyphicon glyphicon-chevron-up"
        aria-hidden="true"
        onClick={() => {
          try {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          } catch (e) {
            window.scroll(0, 0);
          }
        }}
        style={{
          display: visible ? 'block' : 'none'
        }}
      ></div>
    </div>
  );
}
