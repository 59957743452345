import React, { Component } from 'react';
import { Router, Redirect } from '@reach/router';
import LazyRouter from './library/LazyRouter';
import { BasePath } from './library/reach_router';
import Home from './page/home';
class MainRouter extends Component {
  render() {
    return (
      <Router
        basepath={BasePath}
        id="MainRouter"
        style={{
          // ios 键盘弹出会影响这个页面的高度，还是直接的使用fixed固定布局就好了
          // position:"fixed",
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      >
        <Home
          path="/"
          key="HomePage"
          // Component={() =>
          //     import(/* webpackChunkName: "HomePage" */ './page/home')
          // }
        />
        <LazyRouter
          path="/CustomerCase/:page"
          key="CustomerCase"
          Component={() => import(/* webpackChunkName: "CustomerCase" */ './page/CustomerCase')}
        />
        <LazyRouter
          path="/CustomerCase"
          key="CustomerCase"
          Component={() => import(/* webpackChunkName: "CustomerCase" */ './page/CustomerCase')}
        />
        <LazyRouter
          path="/CustomerDetail/:uid"
          key="CustomerDetail"
          Component={() => import(/* webpackChunkName: "CustomerDetail" */ './page/CustomerDetail')}
        />
        <LazyRouter
          path="/ResourceDownloadPage"
          key="ResourceDownloadPage"
          Component={() => import(/* webpackChunkName: "ResourceDownloadPage" */ './page/ResourceDownloadPage')}
        />
        <LazyRouter
          path="/TrialPage"
          key="TrialPage"
          Component={() => import(/* webpackChunkName: "TrialPage" */ './page/TrialPage')}
        />
        <LazyRouter
          path="/NewCenter/classify/:classify/page/:page"
          key="NewCenter"
          Component={() => import(/* webpackChunkName: "NewCenter" */ './page/NewCenter')}
        />
        <LazyRouter
          path="/NewCenter"
          key="NewCenter"
          Component={() => import(/* webpackChunkName: "NewCenter" */ './page/NewCenter')}
        />
        <LazyRouter
          path="/NewDetail/:uid"
          key="NewDetail"
          Component={() => import(/* webpackChunkName: "NewDetail" */ './page/NewDetail')}
        />
        <LazyRouter
          path="/ContratUs"
          key="ContratUs"
          Component={() => import(/* webpackChunkName: "ContratUs" */ './page/ContratUs')}
        />
        <LazyRouter
          path="/JoinUs"
          key="JoinUs"
          Component={() => import(/* webpackChunkName: "JoinUs" */ './page/JoinUs')}
        />
        <LazyRouter
          path="/AboutUs"
          key="AboutUs"
          Component={() => import(/* webpackChunkName: "AboutUs" */ './page/AboutUs')}
        />
        <LazyRouter
          path="/VersionCompare"
          key="VersionCompare"
          Component={() => import(/* webpackChunkName: "VersionCompare" */ './page/VersionCompare')}
        />
        <LazyRouter
          path="/Product"
          key="Producte"
          Component={() => import(/* webpackChunkName: "Product" */ './page/Product')}
        />
        {React.createElement(
          function(props: any) {
            return <Redirect to="/"></Redirect>;
          },
          {
            path: '/*',
            key: '404'
          }
        )}
      </Router>
    );
  }
}

export default MainRouter;
