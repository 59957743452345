import React from 'react';

import './index.less';
import { navigate } from '../../library/reach_router';
import WebpImage from '../../common/webpImage';

interface CustomerCaseProps {
  data: {
    id: number;
    title: string;
    content: string;
    add_time: string;
    update_time: null;
    classify: null;
    company: string;
    imagepath: string;
  }[];
}

function adpaterUrl(path: string) {
  return {
    src: (window as any).RootUrl + path,
    webp: (window as any).RootUrl + path
  };
  if (path.indexOf('img/customer/') > -1) {
    if (path[0] === '/') {
      path = '/static/resource' + path;
    } else {
      path = '/static/resource/' + path;
    }
  }
  let prefix = path.slice(path.lastIndexOf('.'));
  return {
    src: path.replace(prefix, '.jpg'),
    webp: path.replace(prefix, '.webp')
  };
}
function CustomerCase(props: CustomerCaseProps) {
  return (
    <>
      {props.data.map((val, index) => {
        if (index % 2 === 0) {
          return (
            <div className="CustomerCaseItem" key={index}>
              <div className="des">
                <div className="main">
                  <div className="content">{val.title}</div>
                  <div
                    className="detail"
                    onClick={() => {
                      navigate('/CustomerDetail/' + val.id, {
                        state: val
                      });
                    }}
                  >
                    查看详情
                  </div>
                </div>
              </div>
              <div className="cover">
                {React.createElement(WebpImage, adpaterUrl(val.imagepath))}
                <span>{val.company}</span>
              </div>
            </div>
          );
        } else {
          return (
            <div className="CustomerCaseItem" key={index}>
              <div className="cover">
                {React.createElement(WebpImage, adpaterUrl(val.imagepath))}
                <span>{val.company}</span>
              </div>
              <div className="des">
                <div className="main">
                  <div className="content">{val.title}</div>
                  <div
                    className="detail"
                    onClick={() => {
                      navigate('/CustomerDetail/' + val.id, {
                        state: val
                      });
                    }}
                  >
                    查看详情
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </>
  );
}
export default CustomerCase;
