import React, { useMemo } from 'react'
import { RouteComponentProps } from './reach_router'

export type MainProps<
    TParams = {},
    TState = {},
    REST = {}
> = RouteComponentProps<TParams, TState> & REST
export const Loading = class extends React.PureComponent {
    // public id!: INotification
    componentWillMount() {
        // Toast.loading({
        //     content: 'loading..',
        //     duration: 0,
        //     onInit: id => {
        //         this.id = id
        //     },
        // })
    }
    componentWillUnmount() {
        // this.id && Toast.hide(this.id)
    }
    render() {
        return <div></div>
    }
}
export default function LazyRouter<TParams = {}, TState = {}, REST = {}>(
    prop: MainProps<TParams, TState, REST> & {
        Component: () => Promise<{ default: any }>
    }
) {
    const { Component, ...rest } = prop
    const el = useMemo(() => {
        return (
            <React.Suspense fallback={<Loading />}>
                {React.createElement(React.lazy(Component), rest)}
            </React.Suspense>
        )
    }, [Component, rest])
    return el
}
