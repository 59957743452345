import React from 'react';
import './App.css';
import MainRouter from './MainRouter';
import Anchor from './component/Anchor';

const App: React.FC = () => {
  return (
    <div className="App">
      <MainRouter />
      <Anchor />
    </div>
  );
};

export default App;
