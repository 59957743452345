import React, { useState, useEffect } from 'react';
/**
 * @export
 * @param {{
 *     src:string,
 *     webp:string
 *     className?:string
 * }} props
 */
export default function WebpImage(props) {
  const { src, webp, ...lessProps } = props;
  const [img, setImg] = useState('');
  useEffect(() => {
    // ignore
    window.Modernizr.on('webp', function(result) {
      if (result) {
        setImg(webp);
      } else {
        setImg(src);
      }
    });
  }, [webp, src]);
  return img ? <img alt="" src={img} {...lessProps} /> : null;
}
export function WebpAlphaImage(props) {
  const { src, webp, ...lessProps } = props;
  const [img, setImg] = useState('');
  useEffect(() => {
    // ignore
    window.Modernizr.on('webpalpha', function(result) {
      if (result) {
        setImg(webp);
      } else {
        setImg(src);
      }
    });
  }, [webp, src]);
  return img ? <img alt="" src={img} {...lessProps} /> : null;
}
