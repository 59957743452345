import { useEffect } from 'react';

export function showhelptab() {
  window.open(
    'http://webim.qiao.baidu.com//im/index?siteid=10399598&ucid=23144439',
    '在线客服',
    'width=700,height=600'
  );
}
// =============================================================================================
window.history.scrollRestoration && (window.history.scrollRestoration = 'manual');
// Hashes a string
function hash(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = ((hash << 5) - hash + str.charCodeAt(i)) & 0xffffffff;
  }
  return hash;
}
let activeTimeout: NodeJS.Timeout | null = null;
let tryCount = 0;
let _tmp = sessionStorage.getItem('usehistoryStack');

let usehistoryStack: {
  [hashId: string]: {
    historyX: number;
    historyY: number;
  };
} = _tmp ? JSON.parse(_tmp) : {};

window.onbeforeunload = function() {
  // console.log("onbeforeunload")
  // 退出
  activeTimeout && clearInterval(activeTimeout);
  sessionStorage.setItem('usehistoryStack', JSON.stringify(usehistoryStack));
};

let useReset = false;
window.addEventListener(
  'popstate',
  function(e) {
    useReset = true;
    // alert('我监听到了浏览器的返回按钮事件啦'); //根据自己的需求实现自己的功能
  },
  false
);
let activeKey: number | string;
export function useHistoryScroll(name: string) {
  useEffect(() => {
    let state = { ...window.history.state };
    delete state['key'];
    activeKey = JSON.stringify({
      name: name,
      url: window.location.href,
      state: hash(state)
    });
    if (useReset && !usehistoryStack[activeKey]) {
      usehistoryStack[activeKey] = {
        historyX: 0,
        historyY: 0
      };
    }
    activeTimeout = setInterval(() => {
      const { historyX = 0, historyY = 0 } = usehistoryStack[activeKey] || {};
      // console.log('start', name, historyY);
      window.scrollTo(historyX, historyY);
      if (document.documentElement.scrollHeight >= historyY || tryCount > 30) {
        activeTimeout && clearInterval(activeTimeout);
      } else {
        tryCount += 1;
      }
    }, 300);
    useReset = false;
    // console.log({ ...usehistoryStack });
    return function() {
      usehistoryStack[activeKey] = {
        historyX: 0,
        historyY: document.documentElement.scrollTop
      };
      // const { historyX, historyY } = usehistoryStack[activeKey];
      // console.log('end', name, historyX, historyY);
      activeTimeout && clearInterval(activeTimeout);
    };
  }, [name]);
}
