import { Location as HLocation } from 'history';
import { NavigateFn, navigate as SourceNav, NavigateOptions } from '@reach/router';
export type RouteComponentProps<TParams = {}, TState = {}> = Partial<TParams> & {
  path?: string;
  default?: boolean;
  location?: WindowLocation<TState>;
  navigate?: NavigateFn;
  uri?: string;
};
export type WindowLocation<TState = {}> = Window['location'] & HLocation<TState>;

export const BasePath = process.env.PUBLIC_URL;

export function navigate<State = {}>(to: string, options?: NavigateOptions<State>) {
  return SourceNav(BasePath + to, options);
}
