import React from 'react';
import logo from '../../resource/home/logo.png';
import logo_webp from '../../resource/home/logo.webp';
import { Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { Link } from '@reach/router';
import './index.less';
import { WebpAlphaImage } from '../../common/webpImage';
export default function HeaderNav(props: { activeName: string }) {
  return (
    <Navbar collapseOnSelect id="HeaderNav">
      <Navbar.Header>
        <Navbar.Brand>
          <Link to="/" className="companyName">
            <WebpAlphaImage src={logo} webp={logo_webp} />
            <span>太慧科技</span>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <ul className="nav navbar-nav navbar-right">
          <li role="presentation" className={props.activeName === '首页' ? 'active' : ''}>
            <Link to="/" role="button">
              首页
            </Link>
          </li>
          <li role="presentation" className={props.activeName === '产品' ? 'active' : ''}>
            <Link to="/Product" role="button">
              产品
            </Link>
          </li>
          <li role="presentation" className={props.activeName === '在线试用' ? 'active' : ''}>
            <Link to="/TrialPage" role="button">
              在线试用
            </Link>
          </li>
          <li role="presentation" className={props.activeName === '资料下载' ? 'active' : ''}>
            <Link to="/ResourceDownloadPage" role="button">
              资料下载
            </Link>
          </li>
          <li role="presentation" className={props.activeName === '新闻中心' ? 'active' : ''}>
            <Link to="/NewCenter" role="button">
              新闻中心
            </Link>
          </li>
          <li role="presentation" className={props.activeName === '客户案例' ? 'active' : ''}>
            <Link to="/CustomerCase" role="button">
              客户案例
            </Link>
          </li>
          <li role="presentation" className={props.activeName === '版本对比' ? 'active' : ''}>
            <Link to="/VersionCompare" role="button">
              版本对比
            </Link>
          </li>
          <NavDropdown
            title="关于我们"
            id="basic-nav-dropdown"
            active={
              props.activeName === '关于我们' || props.activeName === '加入我们' || props.activeName === '联系我们'
            }
          >
            <li role="presentation">
              <Link
                role="menuitem"
                to="/AboutUs"
                style={{
                  width: '100%',
                  display: 'inline-block'
                }}
              >
                关于我们
              </Link>
            </li>
            <li role="presentation">
              <Link
                role="menuitem"
                to="/ContratUs"
                style={{
                  width: '100%',
                  display: 'inline-block'
                }}
              >
                联系我们
              </Link>
            </li>
            <li role="presentation">
              <Link
                role="menuitem"
                to="/JoinUs"
                style={{
                  width: '100%',
                  display: 'inline-block'
                }}
              >
                加入我们
              </Link>
            </li>
          </NavDropdown>
          <NavItem className="NavTel">全国服务热线：155-0213-3698</NavItem>
        </ul>
      </Navbar.Collapse>
    </Navbar>
  );
}
