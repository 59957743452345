import React from 'react';

import './index.less';
import ico_dianhua from '../../resource/home/ico_dianhua.png';
import ico_dianhua_webp from '../../resource/home/ico_dianhua.webp';
import ico_youxiang from '../../resource/home/ico_youxiang.png';
import ico_youxiang_webp from '../../resource/home/ico_youxiang.webp';
import ico_dizhi from '../../resource/home/ico_dizhi.png';
import ico_dizhi_webp from '../../resource/home/ico_dizhi.webp';
import { showhelptab } from '../../library/util';
import { navigate } from '../../library/reach_router';
import { WebpAlphaImage } from '../../common/webpImage';

export default function PageFooter() {
  return (
    <div id="PageFooter">
      <div className="link">
        <dl className="link-item">
          <li className="link-item-title">官方支持</li>
          <li
            className="item"
            onClick={() => {
              navigate('/ResourceDownloadPage');
            }}
          >
            资料下载
          </li>
          <li
            className="item"
            onClick={() => {
              showhelptab();
            }}
          >
            常见问题
          </li>
          <li
            className="item"
            onClick={() => {
              showhelptab();
            }}
          >
            在线咨询
          </li>
        </dl>
        <dl className="link-item">
          <li className="link-item-title">版本对比</li>
          <li
            className="item"
            onClick={() => {
              navigate('/VersionCompare');
            }}
          >
            UEV ITSM免费版
          </li>
          <li
            className="item"
            onClick={() => {
              navigate('/VersionCompare');
            }}
          >
            UEV ITSM标准版
          </li>
          <li
            className="item"
            onClick={() => {
              navigate('/VersionCompare');
            }}
          >
            UEV ITSM专业版
          </li>
        </dl>
        <dl className="link-item">
          <li className="link-item-title">合作伙伴</li>
          <li className="item">
            <a href="https://www.alibabagroup.com/" target="_blank" rel="noopener noreferrer">
              阿 里 云
            </a>
          </li>
          <li className="item">
            <a href="https://www.tencent.com/" target="_blank" rel="noopener noreferrer">
              腾 讯
            </a>
          </li>
          <li className="item">
            <a href="http://home.baidu.com/" target="_blank" rel="noopener noreferrer">
              百 度
            </a>
          </li>
        </dl>
      </div>
      <div className="connection">
        <dl>
          <li className="title">联系方式</li>
          <li className="tel">
            <WebpAlphaImage src={ico_dianhua} webp={ico_dianhua_webp} />
            <a href="tel:155-0213-3698">155-0213-3698</a>
          </li>
          <li className="email">
            <WebpAlphaImage src={ico_youxiang} webp={ico_youxiang_webp} />
            <a href="mailto:support@itaihui.cn">support@itaihui.cn</a>
          </li>
          <li className="address">
            <WebpAlphaImage src={ico_dizhi} webp={ico_dizhi_webp} />
            <span>深圳市南山区桃园街道同富裕工业城二栋6楼</span>
          </li>
        </dl>
      </div>
    </div>
  );
}
