import React from 'react';

import './index.less';
function Copyright() {
  return (
    <div id="copyright">
      <div>
        <span>Copyright © 2019.深圳市太慧科技有限公司</span>
        <span>版权所有 粤ICP备16044045号</span>
      </div>
    </div>
  );
}
export default Copyright;
