import React, { useState, useEffect } from 'react';
import { RouteComponentProps, navigate } from '../../library/reach_router';
import { Link } from '@reach/router';
import './home.less';
import HeaderNav from '../../component/HeaderNav/index';
import { Carousel, Button } from 'react-bootstrap';
import pic_yuansu from '../../resource/home/pic_yuansu.png';
import pic_yuansu_webp from '../../resource/home/pic_yuansu.webp';
import ico_mouse from '../../resource/home/ico_mouse.png';
import ico_mouse_webp from '../../resource/home/ico_mouse.webp';
import 曝光度_1 from '../../resource/home/曝光度_1.png';
import 曝光度_1_webp from '../../resource/home/曝光度_1.webp';

import ico_zhizaoye from '../../resource/home/ico_zhizaoye.png';
import ico_zhizaoye_webp from '../../resource/home/ico_zhizaoye.webp';
import ico_edu from '../../resource/home/ico_edu.png';
import ico_edu_webp from '../../resource/home/ico_edu.webp';
import ico_jingrongye from '../../resource/home/ico_jingrongye.png';
import ico_jingrongye_webp from '../../resource/home/ico_jingrongye.webp';
import ico_xiaoshougongju from '../../resource/home/ico_xiaoshougongju.png';
import ico_xiaoshougongju_webp from '../../resource/home/ico_xiaoshougongju.webp';
import ico_yiliaoshebei from '../../resource/home/ico_yiliaoshebei.png';
import ico_yiliaoshebei_webp from '../../resource/home/ico_yiliaoshebei.webp';
import ico_dianshangwangzhan from '../../resource/home/ico_dianshangwangzhan.png';
import ico_dianshangwangzhan_webp from '../../resource/home/ico_dianshangwangzhan.webp';

import anquan from '../../resource/home/anquan.png';
import anquan_webp from '../../resource/home/anquan.webp';
import ico_kuaisu from '../../resource/home/ico_kuaisu.png';
import ico_kuaisu_webp from '../../resource/home/ico_kuaisu.webp';
import ico_wending from '../../resource/home/ico_wending.png';
import ico_wending_webp from '../../resource/home/ico_wending.webp';

import CustomerCase from '../../component/CustomerCase';
import CopyRight from '../../component/copyright';
import PageFooter from '../../component/footer';
import { CustomItem } from '../CustomerCase';
import { useHistoryScroll } from '../../library/util';
import { WebpAlphaImage } from '../../common/webpImage';

function HomePage(props: RouteComponentProps) {
  useHistoryScroll('home');

  const [CarouselList, setCarouselList] = useState<
    {
      img: string;
      url?: string;
    }[]
  >([]);

  const [CustomerCaseList, setCustomerCase] = useState<CustomItem[]>([]);
  useEffect(() => {
    fetch('./static/json_api/home.json').then(res => {
      res.json().then(val => {
        (window as any).Modernizr.on('webpalpha', function(result: boolean) {
          setCarouselList(
            (val.Carousel as {
              imagepath: string;
              webpImagePath: string;
              url?: string;
            }[]).map(value => {
              return {
                img: result ? value.webpImagePath : value.imagepath,
                url: value.url
              };
            })
          );
        });
        setCustomerCase(val.CustomerCase);
      });
    });
  }, []);
  return (
    <section
      id="HomePage"
      style={{
        height: '100%',
        position: 'relative'
      }}
    >
      <HeaderNav activeName="首页" />
      {CarouselList && (
        <Carousel controls={false} className="homwCarousel">
          {CarouselList.map((val, index) => {
            return (
              <Carousel.Item
                key={index}
                className={`carouselItem ${val.url ? 'url' : ''}`}
                style={{
                  backgroundImage: `url(${val.img})`
                }}
                onClick={
                  val.url
                    ? () => {
                        navigate(val.url!);
                      }
                    : undefined
                }
              />
            );
          })}
        </Carousel>
      )}

      <section
        className="ViewMore"
        style={{
          position: 'relative'
        }}
      >
        <WebpAlphaImage src={pic_yuansu} webp={pic_yuansu_webp} className="bgImage" />
        <section className="content">
          <div className="title">创新型IT运维软件开发商和运维整体解决方案提供商</div>
          <div className="subtitle">
            Innovative IT operation and maintenance software developer and operations overall solution provider
          </div>
          <p>
            太慧科技在相关行业积累了丰富的信息技术服务经验及行业解决方案，涉及的行业包括制造业、金融业、医疗设备、政府、零售业等。
          </p>
          <p>
            以技术为核心竞争力的科技互联网公司太慧科技致力于为企业、政府等组织机构提供安全、快速、稳定、可信赖的产品和服务，帮助客户快速提升整体的运维管理水平。
          </p>
          <div>
            <Link to="/AboutUs">
              <Button>查看更多</Button>
            </Link>
          </div>
        </section>
      </section>

      <section className="service page1">
        <div className="title">
          <WebpAlphaImage src={ico_mouse} webp={ico_mouse_webp} className="bgImage" />
          <div className="mainTitle">为企业、政府等组织机构提供可信赖的产品和服务</div>
          <div className="subTitle">帮助客户快速提升整体的运维管理水平</div>
        </div>
        <div className="content">
          <div className="item anquan">
            <span className="icon">
              <WebpAlphaImage src={anquan} webp={anquan_webp} className="bgImage" />
            </span>
            <span className="title">安全</span>
            <span className="des">具有监控数据分析与处理技术具有知识库或专家库支持应急事件决策技术</span>
          </div>
          <div className="item ico_kuaisu">
            <span className="icon">
              <WebpAlphaImage src={ico_kuaisu} webp={ico_kuaisu_webp} className="bgImage" />
            </span>
            <span className="title">快速</span>
            <span className="des">具有监控数据分析与处理技术具有知识库或专家库支持应急事件决策技术</span>
          </div>
          <div className="item ico_wending">
            <span className="icon">
              <WebpAlphaImage src={ico_wending} webp={ico_wending_webp} className="bgImage" />
            </span>
            <span className="title">稳定</span>
            <span className="des">具有监控数据分析与处理技术具有知识库或专家库支持应急事件决策技术</span>
          </div>
        </div>
      </section>
      <section className="service solutions">
        <div className="title">
          <WebpAlphaImage src={ico_mouse} webp={ico_mouse_webp} className="bgImage" />
          <div className="mainTitle">积累了丰富的信息技术服务经验及行业解决方案</div>
        </div>
        <div className="content">
          <div className="title">典型的行业解决方案</div>
          <div className="subtitle">涉及的行业包括制造业，金融业，医疗设备，政府，零售业等...</div>
          <div className="item-content clearfix">
            <div className="item">
              <WebpAlphaImage src={ico_zhizaoye} webp={ico_zhizaoye_webp} />
              <div className="name">制造业</div>
              <div className="des">自动化生产线信息化解决方案、智慧车间及智慧工厂解决方案</div>
            </div>
            <div className="item">
              <WebpAlphaImage src={ico_edu} webp={ico_edu_webp} />
              <div className="name">教育业</div>
              <div className="des text-center">领先高效的在线教育解决方案</div>
            </div>
            <div className="item">
              <WebpAlphaImage src={ico_jingrongye} webp={ico_jingrongye_webp} />
              <div className="name">金融业</div>
              <div className="des text-center">客户统一视图解决方案、风险控制解决方案</div>
            </div>
            <div className="item">
              <WebpAlphaImage src={ico_xiaoshougongju} webp={ico_xiaoshougongju_webp} />
              <div className="name">零售业</div>
              <div className="des text-center">客户忠诚度管理等解决方案</div>
            </div>
            <div className="item">
              <WebpAlphaImage src={ico_yiliaoshebei} webp={ico_yiliaoshebei_webp} />
              <div className="name">医疗设备</div>
              <div className="des">主管部门希望24小时不间断监控医院网络设备的性能状况</div>
            </div>
            <div className="item ">
              <WebpAlphaImage src={ico_dianshangwangzhan} webp={ico_dianshangwangzhan_webp} />
              <div className="name">商业网站</div>
              <div className="des text-center">性能监控、远程管理解决方案</div>
            </div>
          </div>
        </div>
      </section>
      <section className="tryOnline">
        <div className="content">
          <div className="title"> 专业的IT运维整体解决方案供应商，您身边的大数据运维专家 </div>
          <div className="item">
            优易维ITSM是完全基于ITIL架构的ITSM(IT服务管理)软件
            <br />
            全面集成了事件管理、 问题管理、变更管理、
            <br />
            资产管理、IT项目管理、知识库、采购与合同管理等功能模块。
            <br />
          </div>
          <div className="item">
            并提供了灵活的流程和表单设计工具，帮助
            企业根据自身特点定制各种业务流程，从而彻底改变错综无序的IT服务现状，提高IT团队的生产效率，改善终端用户的满意度。支持多种语言，目前已帮助超过上千家企业级用户提升和优化IT服务质量。
          </div>
          <div className="entry-title">
            免费版也强大，立即获取License！
            <div className="entry">
              <Button
                onClick={() => {
                  navigate('/TrialPage');
                }}
              >
                在线使用
              </Button>
              <a href="http://demo.itaihui.cn" target="_blank" rel="noopener noreferrer">
                <Button>在线演示</Button>
              </a>
            </div>
            <WebpAlphaImage src={曝光度_1} webp={曝光度_1_webp} width="100%" />
          </div>
        </div>
      </section>
      <section className="service CustomerCase">
        <div className="title">
          <WebpAlphaImage src={ico_mouse} webp={ico_mouse_webp} className="bgImage" />
          <div className="mainTitle">100+真实案例，大企业信赖之选</div>
          <div className="subTitle">
            我们的客户所涉及的行业有软件、制造、金融、物流、电商、高新科技、地产、快销、咨询、事业单位、服务等行业。
          </div>
        </div>
        <div className="content">
          <CustomerCase data={CustomerCaseList} />
          <div className="MoreCase">
            <Link to="/CustomerCase">更多案例 >>></Link>
          </div>
        </div>
      </section>
      <PageFooter />
      <CopyRight />
    </section>
  );
}

export default HomePage;
